$(function() {
    var $rdv = $('#rendez-vous .grid').masonry({
        itemSelector: '.grid-item',
        gutter: 10,
        columnWidth: '.grid-sizer',
        isFitWidth: true
    });

    $rdv.imagesLoaded().progress( function() {
        $rdv.masonry('layout');
    });

    $('.slick-slider-multiple').not('.slick-initialized').on('init reInit beforeChange', function(){
        $('.rendez-vous .slider-arrow').addClass('active');
    });

    $('.slick-slider-multiple').not('.slick-initialized').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        appendArrows: $('.rendez-vous .slider-arrow'),
        prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><svg class="icon icon-arrow"></svg><span class="sr-only">Evenement précédent</span></button>',
        nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button"><svg class="icon icon-arrow"></svg><span class="sr-only">Evenement suivant</span></button>',
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
});